import React, { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import Layout from "../components/layout";

export default ({ location }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleUsernameChange = event => setUsername(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();
    const url = process.env.GATSBY_LOGIN_ENDPOINT;
    // https://stackoverflow.com/questions/49070403/how-to-retrieve-variables-in-php-sent-by-axios-post-request/49070841#49070841
    var form = new FormData();
    form.append("username", username);
    form.append("password", password);
    setIsSending(true);
    axios
      .post(url, form)
      .then(result => {
        // Set cookie to expire in one week.
        const maxAge = 60 * 60 * 24 * 7;
        if (result.data.data) {
          document.cookie = `excelresource=auth200; path=/; max-age=${maxAge}`;
          document.cookie = `actingEntity=${username}; path=/; max-age=${maxAge}`;
          window.location.href = "/";
          console.log("Login ok");
        } else {
          setShowMessage(true);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        setIsSending(false);
      });
  };
  return (
    <Layout
      branding="onlinecourse"
      mainTitle={"excel haltuun"}
      mainTitleClasses={"mt-3 mb-3"}
      headerNavLinkStyle={{ color: "white" }}
      headerBgStyles={{
        backgroundColor: "#165832",
      }}
      tagLine={
        <div className="tagLine-online">
          Verkkokurssit työelämän Excel-taitojen oppimiseen
        </div>
      }
      location={location}
    >
      <div className="container py-4">
        <div className="row justify-content-center">
          <div className="col-4">
            <div className="d-flex justify-content-center mb-4">
              <h4>Kirjaudu sisään</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="loginLabel">Käyttäjätunnus</label>
                <input
                  type="text"
                  className="form-control"
                  id="loginUsername"
                  aria-describedby="käyttäjätunnus"
                  placeholder="Käyttäjätunnus"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Salasana</label>
                <input
                  type="password"
                  className="form-control"
                  id="loginPassword"
                  placeholder="Salasana"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {showMessage ? (
                  <div style={{ padding: "10px 0px 0px 0px", color: "red" }}>
                    Virheellinen käyttäjätunnus tai salasana
                  </div>
                ) : (
                  undefined
                )}

                <div style={{ textAlign: "center" }}>
                  <div>
                    {isSending ? (
                      <BeatLoader size={15} margin={2} />
                    ) : (
                      undefined
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary mt-4">
                    Kirjaudu sisään
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
